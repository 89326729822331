/* You can add global styles to this file, and also import other style files */
@font-face {
 font-family: 'redHatBoldTxt';
 src: url("/assets/fonts/RedHatDisplayB.ttf");
}

@font-face {
 font-family: 'PassOne';
 src: url("/assets/fonts/PassionOne-Bold.ttf");
}

@font-face {
 font-family: 'redHatTxt';
 src: url("/assets/fonts/RedHatDisplay-Regular.ttf");
}

html {
 font-size: 100%;
 -webkit-box-sizing: border-box;
         box-sizing: border-box;
}

*, *::before, *::after {
 -webkit-box-sizing: inherit;
         box-sizing: inherit;
}

body, a, p {
 font-family: 'redHatTxt';
}


.regBold{
 font-family: 'redHatBoldTxt';
}


/*center img*/
.center {
 display: block;
 margin-left: auto;
 margin-right: auto;
}

.acentText{
    font-family: 'PassOne';
}
       
/*Flechas de atras*/
.backsArrows:hover{
    cursor: pointer;
}


/* Boton */
.btnAseg {
    color: #fff !important;
    background-color: #FF6720 !important;
    border-color: #FF6720 !important;
    border-radius: 0px 0px 8px 8px;
    }
    
    .btnAseg:hover {
    color: #fff;
    background-color: #ec4b00 !important;
    border-color: #dd4600 !important;
    }
    
    .btnAseg:focus, .btnAseg.focus {
    color: #fff;
    background-color: #ec4b00 !important;
    border-color: #dd4600 !important;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(212, 83, 23, 0.5) !important;
            box-shadow: 0 0 0 0.2rem rgba(212, 83, 23, 0.5) !important;
    }
    
    .btnAseg.disabled, .btnAseg:disabled {
    color: #fff;
    background-color: #ff8a53;
    border-color: #ff8a53;
    }
    
    .btnAseg:not(:disabled):not(.disabled):active, .btnAseg:not(:disabled):not(.disabled).active,
    .show > .btnAseg.dropdown-toggle {
    color: #fff;
    background-color: #dd4600;
    border-color: #ff8a53;
    }
    
    .btnAseg:not(:disabled):not(.disabled):active:focus, .btnAseg:not(:disabled):not(.disabled).active:focus,
    .show > .btnAseg.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(212, 83, 23, 0.5) !important;
            box-shadow: 0 0 0 0.2rem rgba(212, 83, 23, 0.5) !important;
    }

       
